import {
  TimesIcon,
  UxAction,
  UxActivityIndicator,
  UxDirectionEnum,
  UxIconEnum,
  UxIconLibraryEnum,
  UxMuiIconEnum,
  UxNavbar,
  UxOrientationEnum,
  UxPositionEnum,
  UxShadesEnum,
  UxSizeEnum
} from "@wne-spa/ux-component-library";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../authService/AuthService";
import configMessages from "../../helpers/configMessages";
import { openHelpCenter } from "../../helpers/helpCenter";
import { clearLocalStorage } from "../../helpers/sessionHelper";
import urls from "../../helpers/urls";
import {
  updateActivityIndicator,
  updateAssessmentTab,
  updateBreadCrumbs,
  updateLSBReport,
  updatePageTitle,
  updateReportCard,
  updateSidebarActiveClass,
  updateTabName
} from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch, persistor } from "../../Redux/Store";
import { AdminService } from "../../services/helpers/api.services";
import BulkImportsModal from "../modals/bulk-imports";
import { IUserNavActionProps } from "./IUserNavActionProps";
import { ProductSelector, ProductEnum } from "@wne/mpng-components";
import "./SideBar.scss";

function mapStateToProps(state: ReduxProps) {
  return {
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getUserId: state.getUserId,
    getUserGuId: state.getUserGuId,
    getUserRole: state.getUserRole,
    getSidebarActiveClass: state.getSidebarActiveClass,
    getFlagrKeys: state.getFlagrKeys,
    getActivityIndicator: state.getActivityIndicator,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getOrgLoginCodeAvailable: state.getOrgLoginCodeAvailable,
    getIsInherited: state.getIsInherited,
    getChildDepth: state.getChildDepth,
    getCurrentUserDetails: state.getCurrentUserDetails,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}

const getMuiIcon = (icon: UxIconEnum, prefix: string, size: string = null) => ({
  icon: icon as string,
  size: size === null || size === undefined ? UxSizeEnum.MEDIUM : UxSizeEnum.ABB,
  textPosition: UxPositionEnum.BOTTOM,
  squared: true,
  textClassName: "text-xs mt-2 fontFamily",
  library: prefix
});

type SideBarProps = {
  dispatch: AppDispatch;
};
interface Props extends ReduxProps, SideBarProps {}

const SideNavbar = (props: Props) => {
  const { t } = useTranslation();
  const {
    getUserRoleId,
    getDaDetails,
    getUserId,
    getUserGuId,
    dispatch,
    getSidebarActiveClass,
    getUserRole,
    getFlagrKeys,
    getActivityIndicator,
    getBreadCrumbsData,
    getOrgLoginCodeAvailable,
    getIsInherited,
    getChildDepth,
    getCurrentUserDetails
  } = props;
  const [showCancelModal, setShowCancelModal] = useState(false);
  const handleLogoutClick = async () => {
    // we shouldn't clear  the session because the OIDC-IMLP needs id token to logout api and then it will clear all the sessionstorage.
    //sessionStorage.clear();;
    sendLogoutEvent();
  };
  const [newmenu, setnewMenu] = useState("");
  const [studentRosterModal, setStudentRosterModal] = useState(false);

  async function sendLogoutEvent() {
    try {
      const clientDateTime = moment().format();
      const userAgent = navigator.userAgent;
      const jsonObj = {
        userId: getUserId,
        clientDateTime: clientDateTime,
        userAgent: userAgent,
        userRole: getUserRole
      };

      //added to avoid multiple api calls, when we click on  logout multiple times.
      if (localStorage.getItem("isLogoutClicked") === "false") {
        localStorage.setItem("isLogoutClicked", "true");

        AdminService.saveLogoutevent(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
          //do nothing
        });

        await persistor.purge();
        localStorage.removeItem("lms:isRenew");
        localStorage.removeItem("isLogoutClicked");
        localStorage.removeItem("clever:sso");
        localStorage.removeItem("persist:growth-reports");
        localStorage.removeItem("persist:mypath-reports");
        localStorage.removeItem("oidc:session");
        localStorage.removeItem("page");
        localStorage.removeItem("oidc:state");
        for (let i = 0; i < localStorage.length; i++) {
          if (localStorage.key(i).includes("oidc.")) {
            localStorage.removeItem(localStorage.key(i));
          }
        }
        logout();
      }
    } catch (err) {
      //do nothing
    }
  }

  const [menu, setMenu] = useState("schools");
  const navigate = useNavigate();
  const handleSideNavClick = (menus: string) => {
    if (localStorage.getItem("ala:createStudent") === "true") {
      setShowCancelModal(true);
      setnewMenu(menus);
      setStudentRosterModal(false);
    } else if (localStorage.getItem("ala:addRosterStudent") === "true") {
      setShowCancelModal(true);
      setnewMenu(menus);
      setStudentRosterModal(true);
    } else {
      if (menus.includes("eusers")) {
        localStorage.setItem("page", "students");
      } else if (menus.includes("dainstitutions")) {
        localStorage.setItem("page", "schools");
      } else if (menus.includes("sessions")) {
        localStorage.setItem("page", "academicSessions");
      } else {
        localStorage.setItem("page", "");
      }
      if (menus.includes("reports") || menus.includes("reportcards")) {
        dispatch(updateBreadCrumbs({ crumbs: [{ path: menus, name: "Reports" }] }));
        setMenu("REPORTS");
        dispatch(updateLSBReport(true));
        dispatch(updateSidebarActiveClass("reports"));
        dispatch(updatePageTitle("- Reports"));
      } else if (menus.includes("sessions")) {
        dispatch(
          updateBreadCrumbs({
            crumbs: [{ path: menus, name: "Academic Sessions" }]
          })
        );
        setMenu("Academic Sessions");
        dispatch(updatePageTitle(`- Academic Sessions`));
      } else if (menus.includes("dashboard")) {
        dispatch(
          updateBreadCrumbs({
            crumbs: [{ path: menus, name: "Assignment Builder" }]
          })
        );
        setMenu("Assignment Builder");
        dispatch(updatePageTitle(`- Academic Sessions`));
      } else {
        setMenu(menus);
        dispatch(updateLSBReport(false));
      }

      navigate(menus, { replace: false });
      dispatch(updateAssessmentTab("home"));
      dispatch(updateTabName(null));
      setShowProfileDropDown(false);
    }
  };

  const [showProfileDropDown, setShowProfileDropDown] = useState(false);

  async function handleHelpClick(targetRedirectUrl?: string) {
    dispatch(updateActivityIndicator(true));
    try {
      await openHelpCenter(targetRedirectUrl);
    } catch (err) {
    } finally {
      dispatch(updateActivityIndicator(false));
    }
  }

  const getTallerSidebarItemClass = (props) => {
    if (props.type === getSidebarActiveClass) {
      return "text-light muiiconactiveclass cursor lsbiconshadow  iconspace";
    } else {
      return "text-light muiiconnonactiveclass cursor lsbiconshadow iconspace";
    }
  };

  const getNormalHeightSidebarItemClass = (props) => {
    if (props.type === getSidebarActiveClass) {
      return "text-light muiiconactiveclass cursor lsbiconshadow";
    } else {
      return "text-light muiiconnonactiveclass cursor lsbiconshadow";
    }
  };

  const createUserNavAction = (props: IUserNavActionProps) => (
    <UxAction
      {...{
        //@ts-ignore
        callback: undefined,
        enabled: true,
        priority: "link" as string,
        //@ts-ignore
        className:
          props.type === "assignmentBuilder" || props.type === "domainmapping" || props.type === "academicSessions"
            ? getTallerSidebarItemClass(props)
            : getNormalHeightSidebarItemClass(props),
        ...props
      }}
    />
  );

  let isWneUser = [1, 2, 7].includes(getUserRoleId);

  let pendo = createUserNavAction({
    title: "pendo",
    size: UxSizeEnum.X1,
    className: "pendo-resource-center-badge p-0 mb-2 mx-1",
    squared: true,
    icon: getMuiIcon(UxIconEnum.PENDOHELP, UxIconLibraryEnum.SOLID, UxSizeEnum.ABB)
  });

  const sidebarCustomers = createUserNavAction({
    callback: () => handleSideNavClick("/customers"),
    text: t("Customers"),
    icon: getMuiIcon(
      getSidebarActiveClass === "customers" ? UxMuiIconEnum.SELECTEDBADGE : UxMuiIconEnum.BADGE,
      UxIconLibraryEnum.LIGHT
    ),
    type: "customers"
  });

  const sidebarClasses = createUserNavAction({
    callback: () => handleSideNavClick("/users"),
    text: t("Users"),
    icon: getMuiIcon(
      getSidebarActiveClass === "users" ? UxMuiIconEnum.SELECTEDGROUPS : UxMuiIconEnum.GROUPS,
      UxIconLibraryEnum.LIGHT
    ),
    type: "users"
  });

  const sidebarDomainMapping = getFlagrKeys.DomainMappingFeature
    ? createUserNavAction({
        callback: () => {
          handleSideNavClick("/domainmapping");
        },
        text: t("Domain Mapping"),
        icon: getMuiIcon(
          getSidebarActiveClass === "domainmapping" ? UxMuiIconEnum.SELECTEDDOMAINMAPPING : UxMuiIconEnum.DOMAINMAPPING,
          UxIconLibraryEnum.LIGHT
        ),
        type: "domainmapping"
      })
    : null;

  const sideBarReports = createUserNavAction({
    callback: () => {
      getUsageReportURL();
    },
    text: t("Reports"),
    icon: getMuiIcon(
      getSidebarActiveClass === "reports" ? UxMuiIconEnum.SELECTEDASSESSMENT : UxMuiIconEnum.ASSESSMENT,
      UxIconLibraryEnum.LIGHT
    ),
    type: "reports"
  });

  let sideBarHelp = createUserNavAction({
    callback: () => {
      handleHelpClick();
    },
    text: t("Help"),
    icon: getMuiIcon(
      getSidebarActiveClass === "help" ? UxMuiIconEnum.SELECTEDHELPOUTLINE : UxMuiIconEnum.HELPOUTLINE,
      UxIconLibraryEnum.LIGHT
    ),
    type: "help"
  });

  sideBarHelp = isWneUser ? sideBarHelp : null;
  const sidebarChildren = [
    {
      item: null,
      direction: UxDirectionEnum.END
    }
  ];

  const WNEUSER = [
    {
      item: sidebarCustomers,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: sidebarClasses,
      direction: UxDirectionEnum.BEGINNING
    }
  ];

  if (getUserRoleId === 1) {
    //wne admins only
    WNEUSER.push({
      item: sidebarDomainMapping,
      direction: UxDirectionEnum.BEGINNING
    });
  }

  WNEUSER.push({
    item: sideBarHelp,
    direction: UxDirectionEnum.END
  });

  const DAInstitutes = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/dainstitutions/${getDaDetails.id}`),
    text: t("Schools"),
    icon: getMuiIcon(
      getSidebarActiveClass === "schools" ? UxMuiIconEnum.SELECTEDHOME : UxMuiIconEnum.HOME,
      UxIconLibraryEnum.LIGHT
    ),
    type: "schools"
  });
  const DADistrict = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/dainstitutions/${getDaDetails.id}`),
    text: t("Districts"),
    icon: getMuiIcon(
      getSidebarActiveClass === "districts" ? UxMuiIconEnum.SELECTEDPERSONPINCIRCLE : UxMuiIconEnum.PERSONPINCIRCLE,
      UxIconLibraryEnum.LIGHT
    ),
    type: "districts"
  });

  const DAUsers = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/eusers`),
    text: t("Users"),
    icon: getMuiIcon(
      getSidebarActiveClass === "users" ? UxMuiIconEnum.SELECTEDGROUPS : UxMuiIconEnum.GROUPS,
      UxIconLibraryEnum.LIGHT
    ),
    type: "users"
  });

  const DAAssignmentBuilder = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/assignmentbuilder/districts/${getDaDetails.id}`),
    text: t("Assignment Builder"),
    icon: getMuiIcon(
      getSidebarActiveClass === "assignmentBuilder" ? UxMuiIconEnum.SELECTEDFACTCHECK : UxMuiIconEnum.FACTCHECK,
      UxIconLibraryEnum.LIGHT,
      UxSizeEnum.ABB
    ),
    type: "assignmentBuilder"
  });

  const DAAssessments = createUserNavAction({
    callback: () => {
      checkForMultipleSchools();
    },
    text: t("Assessments"),
    icon: getMuiIcon(
      getSidebarActiveClass === "assessments" ? UxMuiIconEnum.SELECTEDDESCRIPTION : UxMuiIconEnum.DESCRIPTION,
      UxIconLibraryEnum.LIGHT
    ),
    type: "assessments"
  });

  let DAUSER = [
    {
      item: DAInstitutes,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: DAUsers,
      direction: UxDirectionEnum.BEGINNING
    },
    ...(props.getIsMyPathOnly === false ? [{
      item: DAAssessments,
      direction: UxDirectionEnum.BEGINNING
    }] : []),
    {
      item: DAAssignmentBuilder,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: sideBarHelp,
      direction: UxDirectionEnum.END
    },
    {
      item: pendo,
      direction: UxDirectionEnum.END
    }
  ];

  let DAUSERMULTILEVEL = [
    {
      item: DADistrict,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: DAUsers,
      direction: UxDirectionEnum.BEGINNING
    },
    ...(props.getIsMyPathOnly === false ? [{
      item: DAAssessments,
      direction: UxDirectionEnum.BEGINNING
    }] : []),
    {
      item: DAAssignmentBuilder,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: sideBarHelp,
      direction: UxDirectionEnum.END
    },
    {
      item: pendo,
      direction: UxDirectionEnum.END
    }
  ];

  const SAClasses = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/saclasses/${getDaDetails.id}`),
    text: t("Classes"),
    icon: getMuiIcon(
      getSidebarActiveClass === "classes" ? UxMuiIconEnum.SELECTEDAUTOSTORIES : UxMuiIconEnum.AUTOSTORIES,
      UxIconLibraryEnum.LIGHT
    ),
    type: "classes"
  });

  const SACLASSACADEMICSESSIONS = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/sessions/${getDaDetails.id}`),
    text: t(`Academic Sessions`),
    icon: getMuiIcon(
      getSidebarActiveClass === "academicSessions" ? UxMuiIconEnum.SELECTEDCALENDERMONTH : UxMuiIconEnum.CALENDERMONTH,
      UxIconLibraryEnum.LIGHT
    ),
    type: "academicSessions"
  });

  const SAUsers = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/eusers`),
    text: t("Users"),
    icon: getMuiIcon(
      getSidebarActiveClass === "users" ? UxMuiIconEnum.SELECTEDGROUPS : UxMuiIconEnum.GROUPS,
      UxIconLibraryEnum.LIGHT
    ),
    type: "users"
  });

  const SAUser = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/sausers/${getDaDetails.id}`),
    text: t("Users"),
    icon: getMuiIcon(
      getSidebarActiveClass === "user" ? UxMuiIconEnum.SELECTEDGROUPS : UxMuiIconEnum.GROUPS,
      UxIconLibraryEnum.LIGHT
    ),
    type: "user"
  });

  const SASettings = createUserNavAction({
    //@ts-ignore
    callback: () => {
      handleSideNavClick(`/sasettings/${getDaDetails.id}`);
    },
    text: t("Settings"),
    icon: getMuiIcon(
      getSidebarActiveClass === "settings" ? UxMuiIconEnum.SELECTEDSETTINGS : UxMuiIconEnum.SETTINGS,
      UxIconLibraryEnum.LIGHT
    ),
    type: "settings"
  });

  function checkForMultipleSchools() {
    if (getUserRoleId === 4 && getDaDetails.type === 2) {
      handleSideNavClick(`/saassessmentschools/${getDaDetails.id}`);
    } else if (getUserRoleId === 5 && getDaDetails.type === 2) {
      handleSideNavClick(`/teacherassessmentschools/${getDaDetails.id}`);
    } else {
      dispatch(updateAssessmentTab("home"));
      handleSideNavClick(`/assessments/${getDaDetails.id}`);
    }
  }

  const SAAssessments = createUserNavAction({
    //@ts-ignore
    callback: () => {
      checkForMultipleSchools();
    },
    text: t("Assessments"),
    icon: getMuiIcon(
      getSidebarActiveClass === "assessments" ? UxMuiIconEnum.SELECTEDDESCRIPTION : UxMuiIconEnum.DESCRIPTION,
      UxIconLibraryEnum.LIGHT
    ),
    type: "assessments"
  });
  const SAAssignmentBuilder = createUserNavAction({
    //@ts-ignore
    callback: () =>
      handleSideNavClick(
        getDaDetails.type === 2 ? `/assignmentbuilder/schools/${getDaDetails.id}` : `/assignmentbuilder/dashboard`
      ),
    text: t("Assignment Builder"),
    icon: getMuiIcon(
      getSidebarActiveClass === "assignmentBuilder" ? UxMuiIconEnum.SELECTEDFACTCHECK : UxMuiIconEnum.FACTCHECK,
      UxIconLibraryEnum.LIGHT,
      UxSizeEnum.ABB
    ),
    type: "assignmentBuilder"
  });

  let SACLASSES = [
    {
      item: SAClasses,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: SAUser,
      direction: UxDirectionEnum.BEGINNING
    },
    ...(props.getIsMyPathOnly === false ? [{
      item: SAAssessments,
      direction: UxDirectionEnum.BEGINNING
    }] : []),
    {
      item: SACLASSACADEMICSESSIONS,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: SASettings,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: SAAssignmentBuilder,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: sideBarHelp,
      direction: UxDirectionEnum.END
    },
    {
      item: pendo,
      direction: UxDirectionEnum.END
    }
  ];

  const SASchools = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/saschools/${getDaDetails.id}`),
    text: t("Schools"),
    icon: getMuiIcon(
      getSidebarActiveClass === "schools" ? UxMuiIconEnum.SELECTEDHOME : UxMuiIconEnum.HOME,
      UxIconLibraryEnum.LIGHT
    ),
    type: "schools"
  });

  let SASCHOOLS = [
    {
      item: SASchools,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: SAUsers,
      direction: UxDirectionEnum.BEGINNING
    },
    ...(props.getIsMyPathOnly === false ? [{
      item: SAAssessments,
      direction: UxDirectionEnum.BEGINNING
    }] : []),
    {
      item: SAAssignmentBuilder,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: sideBarHelp,
      direction: UxDirectionEnum.END
    },
    {
      item: pendo,
      direction: UxDirectionEnum.END
    }
  ];

  const TSchools = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/teacherschools/${getDaDetails.id}`),
    text: t("Schools"),
    icon: getMuiIcon(
      getSidebarActiveClass === "schools" ? UxMuiIconEnum.SELECTEDHOME : UxMuiIconEnum.HOME,
      UxIconLibraryEnum.LIGHT
    ),
    type: "schools"
  });

  const TClass = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/teacherclass/${getDaDetails.id}`),
    text: t("Classes"),
    icon: getMuiIcon(
      getSidebarActiveClass === "classes" ? UxMuiIconEnum.SELECTEDAUTOSTORIES : UxMuiIconEnum.AUTOSTORIES,
      UxIconLibraryEnum.LIGHT
    ),
    type: "classes"
  });

  const TClasses = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/teacherclasses/${getDaDetails.id}`),
    text: t("Classes"),
    icon: getMuiIcon(
      getSidebarActiveClass === "classes" ? UxMuiIconEnum.SELECTEDAUTOSTORIES : UxMuiIconEnum.AUTOSTORIES,
      UxIconLibraryEnum.LIGHT
    ),
    type: "classes"
  });

  const TStudents = createUserNavAction({
    //@ts-ignore
    callback: () => handleSideNavClick(`/tstudents/${getDaDetails.id}`),
    text: t("Students"),
    icon: getMuiIcon(
      getSidebarActiveClass === "students" ? UxMuiIconEnum.SELECTEDSCHOOL : UxMuiIconEnum.SCHOOL,
      UxIconLibraryEnum.LIGHT
    ),
    type: "students"
  });

  const TAssessments = createUserNavAction({
    //@ts-ignore
    callback: () => {
      checkForMultipleSchools();
    },

    text: t("Assessments"),
    icon: getMuiIcon(
      getSidebarActiveClass === "assessments" ? UxMuiIconEnum.SELECTEDDESCRIPTION : UxMuiIconEnum.DESCRIPTION,
      UxIconLibraryEnum.LIGHT
    ),
    type: "assessments"
  });

  const TAssignmentBuilder = createUserNavAction({
    //@ts-ignore
    callback: () =>
      handleSideNavClick(
        getDaDetails.type === 2 ? `/assignmentbuilder/schools/${getDaDetails.id}` : `/assignmentbuilder/dashboard`
      ),
    text: t("Assignment Builder"),
    icon: getMuiIcon(
      getSidebarActiveClass === "assignmentBuilder" ? UxMuiIconEnum.SELECTEDFACTCHECK : UxMuiIconEnum.FACTCHECK,
      UxIconLibraryEnum.LIGHT,
      UxSizeEnum.ABB
    ),
    type: "assignmentBuilder"
  });

  let TSCHOOLS = [
    {
      item: TClass,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: TSchools,
      direction: UxDirectionEnum.BEGINNING
    },
    ...(props.getIsMyPathOnly === false ? [{
      item: TAssessments,
      direction: UxDirectionEnum.BEGINNING
    }] : []),
    {
      item: TStudents,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: TAssignmentBuilder,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: sideBarHelp,
      direction: UxDirectionEnum.END
    },
    {
      item: pendo,
      direction: UxDirectionEnum.END
    }
  ];

  let TCLASSES = [
    {
      item: TClasses,
      direction: UxDirectionEnum.BEGINNING
    },
    ...(props.getIsMyPathOnly === false ? [{
      item: TAssessments,
      direction: UxDirectionEnum.BEGINNING
    }] : []),
    {
      item: TStudents,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: TAssignmentBuilder,
      direction: UxDirectionEnum.BEGINNING
    },
    {
      item: sideBarHelp,
      direction: UxDirectionEnum.END
    },
    {
      item: pendo,
      direction: UxDirectionEnum.END
    }
  ];

  const [sideBarChild, setSideBarChild] = useState(sidebarChildren);
  const checkTopDAlevel = getOrgLoginCodeAvailable && (getChildDepth === 0 || !getIsInherited);

  useEffect(() => {
    const { pathname } = window.location;
    let path = "";
    if (pathname.lastIndexOf("/") === 0) {
      path = pathname.substring(1, pathname.length);
    } else {
      path = pathname.substring(1, pathname.lastIndexOf("/"));
    }

    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      if (
        path === "customers" ||
        (path === "viewstudent" && getSidebarActiveClass !== "users") ||
        path === "sbulkimports" ||
        path === "school" ||
        path === "institutions" ||
        path === "cstudentplacement" ||
        (path === "cvieweducator" && getSidebarActiveClass !== "users") ||
        path === "classview"
      ) {
        dispatch(updateSidebarActiveClass("customers"));
      } else if (path === "schools") {
        dispatch(updateSidebarActiveClass("schools"));
      } else if (path === "profile") {
        dispatch(updateSidebarActiveClass("profile"));
      } else if (path === "admin") {
        dispatch(updateSidebarActiveClass("admin"));
      } else if (path === "domainmapping") {
        dispatch(updateSidebarActiveClass("domainmapping"));
      } else if (
        path === "users" ||
        path === "wneuser" ||
        (path === "viewstudent" && getSidebarActiveClass === "users") ||
        (path === "cvieweducator" && getSidebarActiveClass === "users")
      ) {
        dispatch(updateSidebarActiveClass("users"));
      } else if (path.includes("reportcards")) {
        dispatch(updateSidebarActiveClass("reports"));
      } else if (path.includes("assignmentbuilder")) {
        dispatch(updateSidebarActiveClass("assignmentBuilder"));
      }
    } else if (getUserRoleId === 3) {
      //@ts-ignore
      if (getDaDetails.type === 1) {
        if (
          path === "dainstitutions" ||
          path === "daschool" ||
          path === "classview" ||
          path === "dvieweducator" ||
          (path === "viewstudent" && getSidebarActiveClass !== "users")
        ) {
          dispatch(updateSidebarActiveClass("schools"));
        } else if (path === "profile") {
          dispatch(updateSidebarActiveClass("profile"));
        } else if (path === "eusers" || (path === "viewstudent" && getSidebarActiveClass === "users")) {
          dispatch(updateSidebarActiveClass("users"));
        } else if (path === "eadmin" || path === "studentplacement") {
          dispatch(updateSidebarActiveClass("admin"));
        } else if (path === "assessments") {
          dispatch(updateSidebarActiveClass("assessments"));
        } else if (path.includes("reportcards")) {
          dispatch(updateSidebarActiveClass("reports"));
        } else if (path.includes("assignmentbuilder")) {
          dispatch(updateSidebarActiveClass("assignmentBuilder"));
        }
        //@ts-ignore
      } else if (getDaDetails.type === 2) {
        if (
          path === "dainstitutions" ||
          path === "daschool" ||
          path === "classview" ||
          path === "dvieweducator" ||
          (path === "viewstudent" && getSidebarActiveClass !== "users")
        ) {
          dispatch(updateSidebarActiveClass("districts"));
        } else if (path === "profile") {
          dispatch(updateSidebarActiveClass("profile"));
        } else if (path === "eadmin" || path === "studentplacement") {
          dispatch(updateSidebarActiveClass("admin"));
        } else if (path === "eusers" || (path === "viewstudent" && getSidebarActiveClass === "users")) {
          dispatch(updateSidebarActiveClass("users"));
        } else if (path === "assessments") {
          dispatch(updateSidebarActiveClass("assessments"));
        } else if (path.includes("reportcards")) {
          dispatch(updateSidebarActiveClass("reports"));
        } else if (path.includes("assignmentbuilder")) {
          dispatch(updateSidebarActiveClass("assignmentBuilder"));
        }
      }
    } else if (getUserRoleId === 4) {
      //@ts-ignore
      if (getDaDetails.type === 1) {
        if (
          path === "saclasses" ||
          path === "classview" ||
          (path === "viewstudent" && getSidebarActiveClass !== "user")
        ) {
          dispatch(updateSidebarActiveClass("classes"));
        } else if (path === "profile") {
          dispatch(updateSidebarActiveClass("profile"));
        } else if (
          path === "sausers" ||
          path === "suvieweducator" ||
          (path === "viewstudent" && getSidebarActiveClass === "user")
        ) {
          dispatch(updateSidebarActiveClass("user"));
        } else if (path === "eadmin" || path === "studentplacement" || path === "bulkimports") {
          dispatch(updateSidebarActiveClass("admin"));
        } else if (path === "sasettings") {
          dispatch(updateSidebarActiveClass("settings"));
        } else if (path === "saassessmentschools" || path === "assessments") {
          dispatch(updateSidebarActiveClass("assessments"));
        } else if (path.includes("reportcards")) {
          dispatch(updateSidebarActiveClass("reports"));
        } else if (path.includes("sessions")) {
          dispatch(updateSidebarActiveClass("academicSessions"));
        } else if (path.includes("assignmentbuilder")) {
          dispatch(updateSidebarActiveClass("assignmentBuilder"));
        }
        //@ts-ignore
      } else if (getDaDetails.type === 2) {
        if (
          path === "saschools" ||
          path === "saclasslist" ||
          path === "classview" ||
          path === "svieweducator" ||
          (path === "viewstudent" && getSidebarActiveClass !== "users")
        ) {
          dispatch(updateSidebarActiveClass("schools"));
        } else if (path === "profile") {
          dispatch(updateSidebarActiveClass("profile"));
        } else if (path === "eadmin" || path === "studentplacement") {
          dispatch(updateSidebarActiveClass("admin"));
        } else if (path === "eusers" || (path === "viewstudent" && getSidebarActiveClass === "users")) {
          dispatch(updateSidebarActiveClass("users"));
        } else if (path === "sasettings") {
          dispatch(updateSidebarActiveClass("settings"));
        } else if (path === "saassessmentschools" || path === "assessments") {
          dispatch(updateSidebarActiveClass("assessments"));
        } else if (path.includes("reportcards")) {
          dispatch(updateSidebarActiveClass("reports"));
        } else if (path.includes("sessions")) {
          dispatch(updateSidebarActiveClass("academicSessions"));
        } else if (path.includes("assignmentbuilder")) {
          dispatch(updateSidebarActiveClass("assignmentBuilder"));
        }
      }
    } else if (getUserRoleId === 5) {
      //@ts-ignore
      if (getDaDetails.type === 1) {
        if (
          path === "teacherclasses" ||
          path === "classview" ||
          (path === "viewstudent" && getSidebarActiveClass === "classes")
        ) {
          dispatch(updateSidebarActiveClass("classes"));
        } else if (path === "tstudents" || (path === "viewstudent" && getSidebarActiveClass !== "classes")) {
          dispatch(updateSidebarActiveClass("students"));
        } else if (path === "profile") {
          dispatch(updateSidebarActiveClass("profile"));
        } else if (path.includes("assignmentbuilder")) {
          dispatch(updateSidebarActiveClass("assignmentBuilder"));
        } else if (path === "assessments" || path === "teacherassessmentschools") {
          dispatch(updateSidebarActiveClass("assessments"));
        } else if (path.includes("reports") || path.includes("reportcards")) {
          dispatch(updateSidebarActiveClass("reports"));
        }
        //@ts-ignore
      } else if (getDaDetails.type === 2) {
        if (
          path === "teacherclass" ||
          (path === "classview" && getSidebarActiveClass === "classes") ||
          (path === "viewstudent" && getSidebarActiveClass === "classes")
        ) {
          dispatch(updateSidebarActiveClass("classes"));
        } else if (path === "tstudents" || (path === "viewstudent" && getSidebarActiveClass === "students")) {
          dispatch(updateSidebarActiveClass("students"));
        } else if (path === "profile") {
          dispatch(updateSidebarActiveClass("profile"));
        } else if (
          path === "teacherschools" ||
          path === "teacherclasses" ||
          (path === "classview" && getSidebarActiveClass !== "classes") ||
          (path === "viewstudent" && getSidebarActiveClass !== "classes")
        ) {
          dispatch(updateSidebarActiveClass("schools"));
        } else if (path === "assessments" || path === "teacherassessmentschools") {
          dispatch(updateSidebarActiveClass("assessments"));
        } else if (path.includes("reports") || path.includes("reportcards")) {
          dispatch(updateSidebarActiveClass("reports"));
        } else if (path.includes("assignmentbuilder")) {
          dispatch(updateSidebarActiveClass("assignmentBuilder"));
        }
      }
    }
    setSideBarChild(sidebarChildren);
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      //@ts-ignore
      setSideBarChild(WNEUSER);
      //@ts-ignore
    } else if (getUserRoleId === 3 && getDaDetails.type === 1) {
      DAUSER.push({
        item: sideBarReports,
        direction: UxDirectionEnum.BEGINNING
      });
      //@ts-ignore
      setSideBarChild(DAUSER);
      //@ts-ignore
    } else if (getUserRoleId === 3 && getDaDetails.type === 2) {
      DAUSERMULTILEVEL.push({
        item: sideBarReports,
        direction: UxDirectionEnum.BEGINNING
      });
      //@ts-ignore
      setSideBarChild(DAUSERMULTILEVEL);
      //@ts-ignore
    } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
      SACLASSES.push({
        item: sideBarReports,
        direction: UxDirectionEnum.BEGINNING
      });

      //@ts-ignore
      setSideBarChild(SACLASSES);
      //@ts-ignore
    } else if (getUserRoleId === 4 && getDaDetails.type === 2) {
      SASCHOOLS.push({
        item: sideBarReports,
        direction: UxDirectionEnum.BEGINNING
      });
      //@ts-ignore
      setSideBarChild(SASCHOOLS);
      //@ts-ignore
    } else if (getUserRoleId === 5 && getDaDetails.type === 2) {
      if (getFlagrKeys["S44-O262-HideLSBReportsForTeachers"]) {
        TSCHOOLS.push({
          item: sideBarReports,
          direction: UxDirectionEnum.BEGINNING
        });
      }

      //@ts-ignore
      setSideBarChild(TSCHOOLS);
      //@ts-ignore
    } else if (getUserRoleId === 5 && getDaDetails.type === 1) {
      if (getFlagrKeys[`S44-O262-HideLSBReportsForTeachers`]) {
        TCLASSES.push({
          item: sideBarReports,
          direction: UxDirectionEnum.BEGINNING
        });
      }

      //@ts-ignore
      setSideBarChild(TCLASSES);
    }

    localStorage.setItem("isLogoutClicked", "false");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserRoleId, getDaDetails, getSidebarActiveClass, getFlagrKeys, menu, getBreadCrumbsData, checkTopDAlevel]);

  function getUsageReportURL() {
    dispatch(updateLSBReport(true));
    dispatch(updateReportCard("home"));

    if (getUserRoleId === 5 && getDaDetails.type === 2) {
      handleSideNavClick(`/schools/reports/${getDaDetails.id}`);
    } else if (getUserRoleId === 4 && getDaDetails.type === 2) {
      handleSideNavClick(`/saschools/reports/${getDaDetails.id}`);
    } else if (
      getUserRoleId === 3 ||
      (getUserRoleId === 4 && getDaDetails.type === 1) ||
      (getUserRoleId === 5 && getDaDetails.type === 1)
    ) {
      return handleSideNavClick(`/reportcards`);
    }
  }

  function navigateProfile() {
    setStudentRosterModal(false);
    dispatch(updateBreadCrumbs({ crumbs: [{ name: "Profile" }] }));
    setShowProfileDropDown(!showProfileDropDown);
    //@ts-ignore
    handleSideNavClick(`/profile/${getDaDetails.id}_${getUserGuId}`);
    localStorage.setItem("ala:createStudent", "false");
    localStorage.setItem("ala:addRosterStudent", "false");
  }

  function exitStudentCreation() {
    setShowCancelModal(false);
    localStorage.setItem("ala:createStudent", "false");
    localStorage.setItem("ala:addRosterStudent", "false");
    handleSideNavClick(newmenu);
  }

  function studentCancelModal() {
    setShowCancelModal(false);
  }
  function renderCancelModal() {
    return (
      <>
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              Exit Screen
            </text>
            <img
              className="confirmCancelImageAddStudent"
              src={TimesIcon}
              alt=""
              onClick={() => {
                setShowCancelModal(false);
              }}
            />
          </div>
          <div className="popuptext">
            <text>
              {studentRosterModal
                ? configMessages.studentRosterExitScreenMessage
                : configMessages.createStudentExitScreenMessage}
            </text>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-10px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              priority={UxShadesEnum.LIGHT}
              className="px-2 py-1 closeBut"
              callback={() => {
                studentCancelModal();
              }}
            />
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Exit"}
              className="px-2 py-1 closeBut"
              callback={() => {
                exitStudentCreation();
              }}
            />
          </div>
        </div>
      </>
    );
  }

  const productSelector = {
    item: (
      <ProductSelector
        //This is to indicate that we are on a MyPath product page
        //the current user might have different mypath products other than reading
        currentProduct={ProductEnum.MyPathReading}
        licensedProducts={getCurrentUserDetails?.organizations?.flatMap((org) => org?.licensedProducts ?? []) ?? []}
        env={window.APP_ENV.sentry_env}
        navigationCleanup={async () => {
          await persistor.purge();
          clearLocalStorage();
          sessionStorage.clear();
        }}
        mpngStyle={true}
        className="mpng-product-selector"
      />
    ),
    direction: UxDirectionEnum.BEGINNING
  };

  return (
    <div>
      {getActivityIndicator ? <UxActivityIndicator type="nouser" /> : null}
      {getUserRoleId > 0 ? (
        <UxNavbar
          color={UxShadesEnum.DARK}
          orientation={UxOrientationEnum.VERTICAL}
          children={[productSelector, ...sideBarChild]}
          role="navigation"
          isMuiIcon={true}
        />
      ) : (
        <UxNavbar
          color={UxShadesEnum.DARK}
          orientation={UxOrientationEnum.VERTICAL}
          children={[productSelector, ...sidebarChildren]}
          role="navigation"
          isMuiIcon={true}
        />
      )}
      {showProfileDropDown ? (
        <div className="profileDropDown">
          <div className="profileItem" onClick={() => navigateProfile()}>
            Profile
          </div>
          <div
            className="profileItem"
            onClick={async () => {
              handleLogoutClick();
            }}
          >
            Logout
          </div>
        </div>
      ) : null}
      <BulkImportsModal
        showBulkModal={showCancelModal}
        children={renderCancelModal()}
        width={studentRosterModal ? "780px" : "670px"}
        height={"225px"}
      />
      <div
        style={{ display: "none" }}
        id="gettokensubmit"
        onClick={() => {
          handleHelpClick();
        }}
      />
      <div
        style={{ display: "none" }}
        id="gettokensubmitforcustomercare"
        onClick={() => {
          handleHelpClick(urls.customercare);
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(SideNavbar);
