import Snackbar from "@material-ui/core/Snackbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  TimesIcon,
  UxAction,
  UxIcon,
  UxIconEnum,
  UxMUITable,
  UxPaginate,
  UxPaginationController,
  UxSearchInput,
  UxShadesEnum,
  UxSizeEnum,
  UxTitleBar
} from "@wne-spa/ux-component-library";
import { MpngExportReport } from "@wne/mpng-components";
import ky from "ky";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import configMessages from "../../helpers/configMessages";
import { paginationControllers } from "../../helpers/configs";
import HelpIcon from "../../images/help_search_icon.png";
import {
  updateActivityIndicator,
  updateCurrentSchoolGuid,
  updateCurrentSchoolID,
  updatePaginationControllerState,
  updateSearchStudentListSort
} from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AdminService } from "../../services/helpers/api.services";
import { UserService } from "../../services/helpers/user.services";
import HiddenForm, { BeginImpersonation } from "../Auth/ImpersonatorHiddenForm";
import ConfirmActions from "../ClassView/ConfirmActions";
import BulkImportsModal from "../modals/bulk-imports";
import "./EducatorsList.scss";
import { CustomStudentProps, IStudents, RowProps, StudentProps } from "./IStudentList";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getUserId: state.getUserId,
    getUserGuId: state.getUserGuId,
    getSearchStudentListSort: state.getSearchStudentListSort,
    getTabChange: state.getTabChange,
    getPaginationControllerState: state.getPaginationControllerState,
    getActivityIndicator: state.getActivityIndicator,
    getFlagrKeys: state.getFlagrKeys,
    getUserRole: state.getUserRole,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned
  };
}
interface Props extends ReduxProps, StudentProps {}

function StudentList(props: Props): JSX.Element {
  const navigate = useNavigate();
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [showTable, setShowTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const {
    dispatch,
    getUserRoleId,
    getDaDetails,
    getUserId,
    getSearchStudentListSort,
    getTabChange,
    getPaginationControllerState,
    getFlagrKeys,
    getUserRole,
    getUserGuId,
    getBreadCrumbsData,
    getIsOrgHasRegistrationOwned
  } = props;
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [emptyTableText, setEmptyTableText] = useState<JSX.Element>();
  const [selected, setSelected] = React.useState<string[]>([]);
  const sortBy = useRef(getSearchStudentListSort.name);
  const sortOrder = useRef(getSearchStudentListSort.val);
  const [studentNameBool, setStudentNameBool] = useState<boolean | null>(null);
  const [usernameBool, setUsernameBool] = useState<boolean | null>(null);
  const [gradeLevelBool, setGradeLevelBool] = useState<boolean | null>(null);
  const [schoolBool, setSchoolBool] = useState<boolean | null>(null);
  const [statusBool, setStatusBool] = useState<boolean | null>(null);
  const columnNames = ["Student Name", "Username", "School", "External ID", "Grade Level", "Status"];
  const rowMapper = [
    ["lastName", "firstName"],
    "loginName",
    "schoolName",
    "districtAssignedId",
    "gradeLevelId",
    "isEnabled"
  ];
  const grades = ["K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "OTHER"];
  const [pageNo, setPageNo] = useState(1);
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const [UID, setUID] = useState("");
  const [actionType, setActionType] = useState("");
  const [showSingleConfirmationModal, setShowSingleConfirmationModal] = useState(false);
  const [showToast, setShowToast] = useState({ val: false, msg: "", type: "" });
  const [studentName, setStudentName] = useState("");
  const [kebabMenuOption, setKebabMenuOption] = React.useState<string[]>([]);
  const [showImpersonationModal, setShowImpersonationModal] = useState(false);
  const [isMigratedID, setIsMigratedID] = useState("");
  const [showErrorModal, setShowErrorModal] = useState<{
    show: boolean;
    errorType: "Inactive" | "NoLogIn" | "IsSynced";
  } | null>(null);
  const [showStudentPassword, setShowStudentPassword] = useState(false);
  const [studentPasswordList, setStudentPasswordList] = useState({
    userGuid: "",
    password: "",
    registrationServiceGuid: ""
  });
  const [studentPasswordFieldError, setStudentPasswordFieldError] = useState<string>("");

  const headersForCsv = [
    {
      label: "School Id",
      id: "schoolId"
    },
    {
      label: "Student Id",
      id: "studentId"
    },
    {
      label: "Student Last Name",
      id: "lastName"
    },
    {
      label: "Student First Name",
      id: "firstName"
    },
    {
      label: "Username",
      id: "userName"
    },
    {
      label: "External ID",
      id: "externalId"
    },
    {
      label: "Grade Level",
      id: "gradeLevel"
    },
    {
      label: "Status",
      id: "status"
    }
  ];

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));

  const alertclasses = alertStyles();

  const helpSearch = (
    <div className="emptyContentWrapper">
      <img src={HelpIcon} className="resultIcon" />
      <div className="helpMessage">No Results Found </div>
      <div className="helpcenter">
        Try modifying the search terms or use the{" "}
        <a href="https://help.edgenuitymypath.com/hc/en-us/articles/4412008995479" target="_blank">
          tips
        </a>{" "}
        in this Help Center article.
      </div>
    </div>
  );

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    setPageNo(1);
    updateState[0] = { paginationPageName: "Students", paginationCount: args };
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 1, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            searchStudents(1);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
      //@ts-ignore
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
  }, [getTabChange]);

  useEffect(() => {
    getSearchStudentListSortFunc();
    localStorage.setItem("page", "studentsRoster");
  }, []);

  const getSearchStudentListSortFunc = () => {
    if (getSearchStudentListSort.val === "desc" || getSearchStudentListSort.val === "DESC") {
      switch (getSearchStudentListSort.name) {
        case "lastname":
          setStudentNameBool(true);
          break;
        case "loginname":
          setUsernameBool(true);
          break;
        case "schoolname":
          setSchoolBool(true);
          break;
        case "gradelevelname":
          setGradeLevelBool(true);
          break;
        default:
          setStatusBool(true);
      }
    } else if (getSearchStudentListSort.val === "asc" || getSearchStudentListSort.val === "ASC") {
      switch (getSearchStudentListSort.name) {
        case "lastname":
          setStudentNameBool(false);
          break;
        case "loginname":
          setUsernameBool(false);
          break;
        case "schoolname":
          setSchoolBool(false);
          break;
        case "gradelevelname":
          setGradeLevelBool(false);
          break;
        default:
          setStatusBool(false);
      }
    }
  };

  useEffect(() => {
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      setKebabMenuOption(["Impersonate"]);
    } else {
      setKebabMenuOption(["Deactivate", "Activate"]);
    }
  }, []);
  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleSearchButtonClick(): void {
    if (searchText !== "") {
      searchStudents(1);
      setSearchResult(true);
      setShowTable(true);
      setPageNo(1);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchStudents(1);
      setSearchResult(true);
      setShowTable(true);
      setPageNo(1);
    }
  }

  function viewStudent(row: RowProps) {
    dispatch(updateCurrentSchoolID(row.orgId));
    dispatch(updateCurrentSchoolGuid(row.orgGuid));
    dispatch(updateActivityIndicator(true));
    navigate(`/viewstudent/${row.orgGuid}_${row.userGuid}`);
  }

  function getSearchRequest(pagenumber: number) {
    let jsonObj;
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      if (id === undefined) {
        jsonObj = {
          Page: pagenumber,
          PageSize: getPaginationControllerState[0].paginationCount,
          SearchText: searchText,
          SortBy: sortBy.current,
          SortOrder: sortOrder.current
        };
      } else {
        jsonObj = {
          Page: pagenumber,
          PageSize: getPaginationControllerState[0].paginationCount,
          SearchText: searchText,
          OrgGuid: id,
          UserGuid: getUserGuId,
          SearchingInsideDistrict: true,
          SortBy: sortBy.current,
          SortOrder: sortOrder.current
        };
      }
    } else if (getUserRoleId === 3) {
      let oid;
      if (id === undefined) {
        oid = getDaDetails.id;
      } else {
        oid = id;
      }
      jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[0].paginationCount,
        SearchText: searchText,
        OrgGuid: oid,
        UserGuid: getUserGuId,
        SearchingInsideDistrict: true,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current
      };
    } else {
      jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[0].paginationCount,
        SearchText: searchText,
        UserGuid: getUserGuId,
        SearchingInsideDistrict: false,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current
      };
    }
    return jsonObj;
  }

  async function searchStudents(pagenumber: number) {
    try {
      dispatch(updateActivityIndicator(true));
      setSearchResult(true);
      dispatch(
        updateSearchStudentListSort({
          name: sortBy.current,
          val: sortOrder.current
        })
      );

      const request = getSearchRequest(pagenumber);

      await UserService.searchStudentsWithGuid(request).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotResponse(data);
        } else {
          dispatch(updateActivityIndicator(false));
          setEmptyTableText(helpSearch);
          setData({
            page: 0,
            size: 0,
            pageSize: 0,
            values: []
          });
        }
      });
    } catch (err) {
      //do nothing
    }
  }

  async function gotResponse(responsedata: Response) {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    let allData = JSON.parse(await responsedata.text());
    if (sortBy.current === "lastname") {
      setUsernameBool(null);
      setGradeLevelBool(null);
      setStatusBool(null);
      setSchoolBool(null);
    } else if (sortBy.current === "loginname") {
      setStudentNameBool(null);
      setGradeLevelBool(null);
      setStatusBool(null);
      setSchoolBool(null);
    } else if (sortBy.current === "schoolname") {
      setStudentNameBool(null);
      setUsernameBool(null);
      setGradeLevelBool(null);
      setStatusBool(null);
    } else if (sortBy.current === "gradelevelname") {
      setStudentNameBool(null);
      setUsernameBool(null);
      setStatusBool(null);
      setSchoolBool(null);
    } else {
      setStudentNameBool(null);
      setUsernameBool(null);
      setGradeLevelBool(null);
      setSchoolBool(null);
    }
    if (allData.size === 0) {
      setEmptyTableText(helpSearch);
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
    } else {
      let students = {
        page: allData.page,
        size: allData.size,
        pageSize: allData.pageSize,
        values: []
      };
      for (let x in allData.values) {
        let val = allData.values[x];
        val.gradeLevelId = allData.values[x].gradeLevelId === -1 ? "PK" : grades[allData.values[x].gradeLevelId - 1];
        //@ts-ignore
        students.values.push(val);
      }
      setData(students);
    }
    dispatch(updateActivityIndicator(false));
  }

  async function getCSVData(csvCallback: (data: any) => void) {
    dispatch(updateActivityIndicator(true));
    const request = getSearchRequest(1);
    try {
      const { status, data } = await UserService.getStudentRosterUserlevelCSVData(request);
      if (status === 200) {
        const csvData = JSON.parse(await data.text());
        csvCallback({rows: csvData, columns: headersForCsv});
      }
      dispatch(updateActivityIndicator(false));
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResult) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResult) {
      return (
        <div className="searchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResult) {
      setSearchText("");
    } else if (searchText !== "" && searchResult) {
      setSearchText("");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      setSearchResult(false);
      setShowTable(false);
      setPageNo(1);
    }
  }

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  function sortChange(type: string) {
    switch (type) {
      case "Student Name":
        sortOrder.current = !studentNameBool ? "desc" : "asc";
        sortBy.current = "lastname";
        break;
      case "Username":
        sortOrder.current = !usernameBool ? "desc" : "asc";
        sortBy.current = "loginname";
        break;
      case "School":
        sortOrder.current = !schoolBool ? "desc" : "asc";
        sortBy.current = "schoolname";
        break;
      case "Grade Level":
        sortOrder.current = !gradeLevelBool ? "desc" : "asc";
        sortBy.current = "gradelevelname";
        break;
      default:
        sortOrder.current = !statusBool ? "desc" : "asc";
        sortBy.current = "isenabled";
    }
    searchStudents(1);
    setPageNo(1);
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    searchStudents(value);
    setPageNo(value);
  };

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }
  async function handelError(responsedata: ky.HTTPError) {
    let response = JSON.parse(await responsedata.response.text());
    if (response.message.includes("DuplicateKey")) {
      if (response.message.includes("Username")) {
        setShowToast({
          val: true,
          msg: "User can't be Activate, UserName already exist",
          type: "error"
        });
      } else if (response.message.includes("StudentNumber") || response.message.includes("Tag")) {
        setShowToast({
          val: true,
          msg: "User can't be Activate, ExternalID already exist",
          type: "error"
        });
      } else if (response.message.includes("NweaId")) {
        setShowToast({
          val: true,
          msg: "User can't be Activate, NweaId already exist",
          type: "error"
        });
      } else if (response.message.includes("RenaissanceId")) {
        setShowToast({
          val: true,
          msg: "User can't be Activate, RenaissanceId already exist",
          type: "error"
        });
      } else {
        setShowToast({
          val: true,
          msg: configMessages.ActivateStudentErrorMessage,
          type: "error"
        });
      }
    } else if (response?.message.includes("Unable to Activate")) {
      if (getIsOrgHasRegistrationOwned && getFlagrKeys["ReadinessFeature_Users_Groups_Events"]) {
        setShowToast({
          val: true,
          msg: `${response?.message},Username or StudentNumber or NweaId or RenaissanceId already exist`,
          type: "error"
        });
      } else {
        setShowToast({
          val: true,
          msg: configMessages.ActivateStudentsErrorMessage,
          type: "error"
        });
      }
    } else {
      setShowToast({
        val: true,
        msg: configMessages.ActivateStudentsErrorMessage,
        type: "error"
      });
    }
    dispatch(updateActivityIndicator(false));
  }

  async function activateStudent() {
    if (getIsOrgHasRegistrationOwned && getFlagrKeys["ReadinessFeature_Users_Groups_Events"]) {
      if (!showStudentPassword) {
        setStudentPasswordList({
          userGuid: UID,
          password: "",
          registrationServiceGuid: UID
        });
        setShowStudentPassword(true);
      }
      if (showStudentPassword) {
        await handleActivateStudent();
      }
    } else {
      await handleActivateStudent();
    }
  }

  const handleActivateStudent = async () => {
    if (getIsOrgHasRegistrationOwned && getFlagrKeys["ReadinessFeature_Users_Groups_Events"]) {
      if (
        studentPasswordList?.password !== "" &&
        studentPasswordList?.password.length >= 4 &&
        !/\\/g.test(studentPasswordList?.password) &&
        studentPasswordList?.password.length <= 256
      ) {
        setStudentPasswordFieldError("");
        await callActivateStudent();
      } else {
        setStudentPasswordFieldError(
          studentPasswordList?.password.length < 4
            ? configMessages.CreateStudentPasswordValidationMin
            : studentPasswordList?.password !== "" && /\\/g.test(studentPasswordList?.password)
            ? configMessages.CreateStudentPasswordValidationControlCharacter
            : studentPasswordList?.password.length > 256
            ? configMessages.CreateEducatorpasswordValdiationMax
            : ""
        );
      }
    } else {
      await callActivateStudent();
    }
  };

  const callActivateStudent = async () => {
    try {
      setShowSingleConfirmationModal(false);
      setShowStudentPassword(false);
      dispatch(updateActivityIndicator(true));
      let studentGuid =
        getIsOrgHasRegistrationOwned && getFlagrKeys["ReadinessFeature_Users_Groups_Events"]
          ? [studentPasswordList]
          : [{ userGuid: UID, password: "", registrationServiceGuid: UID }];
      await UserService.activateStudentsWithGuid(studentGuid).then(
        ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
          if (status === 200) {
            setShowToast({
              val: true,
              msg: configMessages.activateIndividualStudentMsg,
              type: "success"
            });
            searchStudents(1);
          } else {
            handelError(data as ky.HTTPError);
          }
        }
      );
    } catch (err) {
      setShowToast({
        val: true,
        msg: configMessages.ActivateStudentErrorMessage,
        type: "error"
      });
      dispatch(updateActivityIndicator(false));
    }
  };

  async function deactivateStudent() {
    try {
      setShowSingleConfirmationModal(false);
      dispatch(updateActivityIndicator(true));
      let studentGuid = [UID];
      UserService.deactivateStudentsWithGuid(studentGuid).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setShowToast({
              val: true,
              msg: configMessages.deactivateIndividualStudentMsg,
              type: "success"
            });
            searchStudents(1);
          } else {
            setShowToast({
              val: true,
              msg: configMessages.DeactivateStudentErrorMessage,
              type: "error"
            });
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      setShowToast({
        val: true,
        msg: configMessages.DeactivateStudentErrorMessage,
        type: "error"
      });
      dispatch(updateActivityIndicator(false));
    }
  }

  const closeSingleConfirmationModal = () => {
    setShowSingleConfirmationModal(false);
    setShowStudentPassword(false);
  };

  function renderImpersonationModal() {
    return (
      <>
        <HiddenForm
          ImpersonatedUserId={isMigratedID}
          PostLogoutRedirectURL={window.location.pathname}
          tabInfo={"&activeTab=students&activeSubTab=roster"}
        />
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              Entering Impersonation Session
            </text>
            <img
              className="confirmCancelImage"
              src={TimesIcon}
              alt=""
              onClick={() => {
                setShowImpersonationModal(false);
              }}
            />
          </div>
          <hr></hr>
          <div>
            <div className="studentNameHolder">Student: {studentName}</div>
            <text>
              What does it mean to impersonate a user?
              <ul>
                <li>You will be able to take actions in the student’s name.</li>
                <li>Any actions you take can be tracked.</li>
              </ul>
              Please confirm you want to impersonate this user.
            </text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-20px",
              marginTop: "20px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              priority={UxShadesEnum.LIGHT}
              className="px-2 py-1 closeBut"
              callback={() => {
                setShowImpersonationModal(false);
              }}
            />
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Yes"}
              className="px-2 py-1 closeBut"
              callback={() => {
                BeginImpersonation();
                sendLogoutEvent();
                setShowImpersonationModal(false);
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function renderErrorModal() {
    let errorText;
    switch (showErrorModal?.errorType) {
      case "Inactive":
        errorText = `The student's access has been deactivated. Logging in as the student cannot proceed.`;
        break;
      case "NoLogIn":
        errorText = "This student has not yet logged in to MyPath and cannot be impersonated.";
        break;
      case "IsSynced":
        errorText = "This student is managed from a migration and cannot be updated";
        break;
      default:
        errorText = "";
    }
    return (
      <>
        <div className="manipContainer">
          <div className="bulkHeader">
            <img className="confirmCancelImage" src={TimesIcon} alt="" onClick={() => setShowErrorModal(null)} />
          </div>
          <hr></hr>
          <div style={{ margin: "85px 50px", fontWeight: 600 }}>
            <text>{errorText}</text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-20px",
              marginTop: "20px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Ok"}
              className="px-2 py-1 closeBut"
              callback={() => setShowErrorModal(null)}
            />
          </div>
        </div>
      </>
    );
  }

  function customStudentOptionCallback(args: CustomStudentProps) {
    if (args.data.isSynced && (args.type === "Deactivate" || args.type === "Activate")) {
      setShowErrorModal({ show: true, errorType: "IsSynced" });
      return;
    }
    if (args.type === "Deactivate") {
      setActionType("deactivate");
      setShowSingleConfirmationModal(true);
    } else if (args.type === "Activate") {
      setActionType("activate");
      setShowSingleConfirmationModal(true);
    } else {
      try {
        dispatch(updateActivityIndicator(true));
        // UserService.getImpersonationUserDetails(args.data.userId).then(
        UserService.getImpersonationUserDetailsWithGuid(args.data.userGuid).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotMigratedID(data);
            } else {
              //do nothing
            }
          }
        );
      } catch (err) {
        alert("error");
      }

      let name = args.data.firstName + ", " + args.data.lastName;
      setStudentName(name);
    }
    setUID(args.data.userGuid);
  }
  async function gotMigratedID(data: Response) {
    let details = JSON.parse(await data.text());
    if (details.isOrganizationActive) {
      if (details.isEnabled) {
        if (details.isMigrated) {
          setShowImpersonationModal(true);
          setIsMigratedID(details.migratedUserGuid);
        } else {
          setShowErrorModal({ show: true, errorType: "NoLogIn" });
        }
      } else {
        setShowErrorModal({ show: true, errorType: "Inactive" });
      }
    } else {
      setShowErrorModal({ show: true, errorType: "Inactive" });
    }
    dispatch(updateActivityIndicator(false));
  }

  async function sendLogoutEvent() {
    try {
      const clientDateTime = moment().format();
      const userAgent = navigator.userAgent;
      const jsonObj = {
        userId: getUserId,
        clientDateTime: clientDateTime,
        userAgent: userAgent,
        userRole: getUserRole
      };
      AdminService.saveLogoutevent(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        //do nothing
      });
    } catch (err) {
      //do nothing
    }
  }
  const searchResultSchoolInputClass = () => {
    if (!searchResult) {
      return "schoolFullInputTextMiddle";
    } else {
      return "schoolInputTextMiddle";
    }
  };
  const searchTextSchoolInputClass = () => {
    if (searchText) {
      return "schoolFullInputTextTop";
    } else {
      return "schoolInputTextTop";
    }
  };

  const renderStudentPassword = () => {
    return (
      <div className="passwordListWrapper">
        <div className="passwordListSection">
          <div className="passwordListBlock">
            <p className="passwordListLabel">
              Student Name: &nbsp;
              {getStudentPasswordValue("name")}
            </p>
            <p className="passwordListLabel">Username: &nbsp; {getStudentPasswordValue("loginName")}</p>
            <div className="studentPasswordInputFlexContainer">
              <p>Password:</p>
              <input
                name="student-password"
                className="passwordListInput"
                style={{
                  border: `1px solid ${studentPasswordFieldError ? "red" : "#dddddd"}`
                }}
                value={studentPasswordList?.password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!event.target.value.includes(" ")) {
                    setStudentPasswordList({
                      ...studentPasswordList,
                      password: event.target.value
                    });
                  }
                }}
                onFocus={() => setStudentPasswordFieldError("")}
              />
            </div>
            {studentPasswordFieldError ? (
              <span className="passwordListInputError">{studentPasswordFieldError}</span>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const getStudentPasswordValue = (field: string) => {
    let studentPassValue = "";
    const filteredStudentList = data?.values?.filter((item) => item.userGuid === studentPasswordList?.userGuid);
    if (field === "name") {
      studentPassValue = `${filteredStudentList[0]?.lastName}, ${filteredStudentList[0]?.firstName}`;
      return studentPassValue;
    } else {
      studentPassValue = filteredStudentList[0][field];
      return studentPassValue;
    }
  };

  return (
    <>
      <div className="schools">
        {props.showTitle && <UxTitleBar title="Student Roster" />}
        {((!showTable && (getUserRoleId === 3 || getUserRoleId === 4)) ||
          ((getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) &&
            window.location.pathname.includes("institutions"))) && (
          <div className="search-csv-export-container">
            <MpngExportReport
              reportName={
                "Student Roster_" +
                (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7
                  ? getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name.replace(/[.]/g, " ")
                  : getDaDetails.name.replace(/[.]/g, " ")) +
                "_" +
                (new Date(`${new Date()}`).getUTCMonth() + 1) +
                "_" +
                new Date(`${new Date()}`).getUTCDate() +
                "_" +
                new Date(`${new Date()}`).getUTCFullYear() +
                "_" +
                Date.now()
              }
              includePDF={false}
              csvCallback={getCSVData}
            />
          </div>
        )}
        <div className={!showTable ? "searchMiddle" : "searchTop"}>
          {!showTable ? <span className="searchHeading">Search For Students</span> : null}
          <UxSearchInput
            inputClassName={showTable === false ? searchResultSchoolInputClass() : searchTextSchoolInputClass()}
            flexClassName={showTable === false ? "schoolFlexHolderMiddle" : "schoolFlexHolderTop"}
            placeHolder="Search Student Name, Username, External ID"
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e)}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              handleKeyPress(e);
            }}
            searchButtonClassName="searchButton"
            searchButtonContent={renderSearchButton()}
            onSearchClick={() => {
              handleSearchButtonClick();
            }}
            clearButtonClassName="clearButton"
            clearButtonContent={renderCancelButton()}
            onClearClick={() => {
              clearButtonClick();
            }}
          />
          {showTable && (getUserRoleId === 3 || getUserRoleId === 4) && (
            <div className="search-csv-export-container">
              <MpngExportReport
                reportName={
                  "Student Roster_" +
                  getDaDetails.name.replace(/[.]/g, " ") +
                  "_" +
                  (new Date(`${new Date()}`).getUTCMonth() + 1) +
                  "_" +
                  new Date(`${new Date()}`).getUTCDate() +
                  "_" +
                  new Date(`${new Date()}`).getUTCFullYear() +
                  "_" +
                  Date.now()
                }
                includePDF={false}
                csvCallback={getCSVData}
              />
            </div>
          )}
        </div>
        <div ref={elementRef}>
          {showTable ? (
            <UxMUITable
              MUITableClassName={data.values.length === 0 ? "emptyTable1" : "StudentsscrollableTable"}
              columnNames={columnNames}
              rowMapper={rowMapper}
              rowData={(data as IStudents).values}
              showColumnCheckbox={false}
              showKebabMenu={kebabMenuOption.includes("Impersonate")}
              showImpersonationKebabMenu={true}
              customImpersonationKebabOption={customStudentOptionCallback}
              KebabMenuOption={kebabMenuOption}
              showNameKebabMenu={false}
              viewChild={viewStudent}
              emptyTableText={emptyTableText}
              checkboxRef="loginName"
              selected={selected}
              setSelected={setSelected}
              mtableHeight={tableHgt}
              showHelpIconFlag={true}
              showSortList={[true, true, true, false, true, true]}
              disableKebabMenuOverValue="isSynced"
              removeEditabilityFlag={true}
              sortListMapper={{
                "Student Name": [
                  studentNameBool,
                  setStudentNameBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                Username: [
                  usernameBool,
                  setUsernameBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                School: [
                  schoolBool,
                  setSchoolBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                "Grade Level": [
                  gradeLevelBool,
                  setGradeLevelBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                Status: [
                  statusBool,
                  setStatusBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ]
              }}
            />
          ) : null}
        </div>
        {showTable ? (
          <div className="fixedpagination">
            <UxPaginate
              data={data}
              page={pageNo}
              paginationSize={getPaginationControllerState[0].paginationCount}
              className={classes.root}
              handleChange={handleChange}
            />
            {data.size > 50 && (
              <UxPaginationController
                rowsList={paginationControllers}
                setRowsCount={getPaginationControllerState[0].paginationCount}
                callback={callback}
              />
            )}
          </div>
        ) : null}
      </div>
      {showSingleConfirmationModal ? (
        <ConfirmActions
          title="Confirm"
          message={
            actionType === "deactivate"
              ? getFlagrKeys["ReadinessFeature_Users_Groups_Events"] && getIsOrgHasRegistrationOwned
                ? configMessages.DeactivateStudentFromRegistration
                : configMessages.deactivateIndividualStudent
              : configMessages.activateIndividualStudent
          }
          close={closeSingleConfirmationModal}
          callback={actionType === "activate" ? activateStudent : deactivateStudent}
          body={showStudentPassword ? renderStudentPassword() : null}
        />
      ) : null}
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "", type: "" });
          }}
        >
          {showToast.type === "success" ? (
            <Alert
              severity="success"
              style={{ backgroundColor: "#36BBA4", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", type: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          ) : (
            <Alert
              severity="error"
              style={{ backgroundColor: "#f4473c", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", type: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          )}
        </Snackbar>
      </div>
      <BulkImportsModal
        showBulkModal={showImpersonationModal || showErrorModal?.show}
        children={showImpersonationModal ? renderImpersonationModal() : renderErrorModal()}
        width="580px"
        height="340px"
      />
    </>
  );
}

export default connect(mapStateToProps)(StudentList);
