import jwt_decode from "jwt-decode";
import { User } from "oidc-client";
import React, { useEffect, useState } from "react";
import { LogProvider } from "react-logger-js";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppDispatch } from "../src/Redux/Store";
import { getUser, login, logout, renewToken } from "./authService/AuthService";
import GetToken from "./authService/GetToken";
import HasSession from "./authService/hasSession";
import BoundRoute from "./BoundRoute";
import AcademicSessions from "./components/AcademicSessions/AcademicSessions";
import AssignmentBuilder from "./components/AssignmentBuilder/AssignmentBuilder";
import AssignmentBuilderDistricts from "./components/AssignmentBuilder/AssignmentBuilderDistricts";
import AssignmentBuilderSchools from "./components/AssignmentBuilder/AssignmentBuilderSchools";
import NoLicenseProducts from "./components/AssignmentBuilder/NoLicenseProducts";
import BulkImportsView from "./components/BulkImports/BulkImportsView";
import SchoolBulkImportsView from "./components/BulkImports/SchoolBulkImportsView";
import AddStudentRoster from "./components/ClassView/AddStudentRoster";
import ClassSummaryReport from "./components/ClassView/ClassSummaryreport";
import ClassView from "./components/ClassView/ClassView";
import ClassProgress from "./components/ClassView/ClassProgress";
import Customers from "./components/Customer/Customer";
import DAInstitutions from "./components/DAPages/DAInstitutions";
import DAList from "./components/DAPages/DAList";
import DASchool from "./components/DAPages/DASchool";
import SAClasses from "./components/DAPages/SAClasses";
import SAClassList from "./components/DAPages/SAClassList";
import SAClassView from "./components/DAPages/SAClassView";
import SASchools from "./components/DAPages/SASchools";
import SASettings from "./components/DAPages/SASettings";
import SAUsers from "./components/DAPages/SAUsers";
import TeacherClasses from "./components/DAPages/TeacherClasses";
import TeacherClassView from "./components/DAPages/TeacherClassView";
import TeacherSchools from "./components/DAPages/TeacherSchools";
import TStudents from "./components/DAPages/TStudents";
import DomainMapping from "./components/DomainMapping/DomainMapping";
import CustomerEducatorProfile from "./components/EducatorProfile/CustomerEducatorProfile";
import DistrictEducatorProfile from "./components/EducatorProfile/DistrictEducatorProfile";
import DSchoolEducatorProfile from "./components/EducatorProfile/DSchoolEducatorProfile";
import EditOwnProfile from "./components/EducatorProfile/EditOwnProfile";
import EducatorProfile from "./components/EducatorProfile/EducatorProfile";
import SAUserEducatorProfile from "./components/EducatorProfile/SAUserEducatorProfile";
import SchoolEducatorProfile from "./components/EducatorProfile/SchoolEducatorProfile";
import USchoolEducatorProfile from "./components/EducatorProfile/USchoolEducatorProfile";
import UserEducatorProfile from "./components/EducatorProfile/UserEducatorProfile";
import EAdmin from "./components/EducatorView/Admin";
import EUsers from "./components/EducatorView/Users";
import Institutions from "./components/Institutions/Institutions";
import Signin from "./components/Login/authenticator";
import LoginComponent from "./components/Login/login";
import "./components/Login/login.scss";
import MppLaunch from "./components/Login/MppLaunch";
import ImpersonationErrorPage from "./components/PageNotFound/imersonation-error-page";
import PageNotFound from "./components/PageNotFound/page-not-found";
import PageTitle from "./components/PageTitle/PageTitle";
import BenchmarkGrowthReports from "./components/reports/BenchmarkGrowthReports";
import DsdsReport from "./components/reports/DsdsReport";
import MyPathAssessmentReports from "./components/reports/MyPathAssessmentReports";
import Reports from "./components/reports/Reports";
import ReportsTab from "./components/reports/ReportsTab";
import CumulativeStandardsProficiency from "./components/reports/CumulativeStandardsProficiency";
import ForgotPassword from "./components/ResetPassword/responsiveforgotpwd";
import ResetPassword from "./components/ResetPassword/responsiveresetpwd";
import School from "./components/School/School";
import Schools from "./components/Schools/Schools";
import Logout from "./components/StudentApp/Logout/Logout";
import StudentBoundRoute from "./components/StudentBoundRoute/StudentBoundRoute";
import StudentClasses from "./components/StudentProfile/StudentClasses";
import StudentProgress from "./components/StudentProfile/StudentProgress";
import StudentsProfile from "./components/StudentProfile/StudentsProfile";
import CreateStudent from "./components/Students/createStudent";
import SchoolStudentPlacement from "./components/Students/SchoolStudentPlacement";
import StudentView from "./components/Students/StudentView";
import Users from "./components/Users/Users";
import Assessment from "./components/ViewCustomer/Assessment";
import AssessmentSchedule from "./components/ViewCustomer/AssessmentSchedule";
import WNEProfile from "./components/WNEUserProfile/WNEProfile";
import GetBreakpoint from "./GetBreakpoint";
import RoutesHelper from "./helpers/routesHelper";
import {
  updateAssessmentTab,
  updateBreadCrumbs,
  updateBreakpoint,
  updatePreviousUrl,
  updateSubjectName,
  updateTabName
} from "./Redux/Action";
import ReduxProps from "./Redux/Redux.props";
import { RootComponentProps } from "./root-component.props";
import DsdsReportLaunch from "./components/reports/DsdsReportLaunch";
import getStructuredBreadCrumbsData from "./helpers/routesHelperMethods";

type RoutingProps = {
  dispatch: AppDispatch;
};

type BreadcrumbsData = {
  crumbs: CrumbsData[];
  isBreadcrumbsChanged?: boolean;
};
type BenchmarkGrowthReport = {
  name?: string;
  crumbs?: CrumbsData[];
};
type CrumbsData = {
  name: string;
  path?: string;
};

interface Props extends ReduxProps, RoutingProps, RootComponentProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getUserRoleId: state.getUserRoleId,
    getActivityIndicator: state.getActivityIndicator,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getFlagrKeys: state.getFlagrKeys,
    getLSBReport: state.getLSBReport,
    getPreviousBenchmarkGrowthReportURL: state.getPreviousBenchmarkGrowthReportURL,
    getDaDetails: state.getDaDetails,
    getAssessmentTab: state.getAssessmentTab,
    getTabChange: state.getTabChange,
    getUpdateLevel: state.getUpdateLevel,
    getAssignmentBuilderOrg: state.getAssignmentBuilderOrg,
    updateRoleLevel: state.updateRoleLevel,
    getSchoolGuids: state.getSchoolGuids,
    getPreviousMyPathURL: state.getPreviousMyPathURL,
    getSubjectName: state.getSubjectName
  };
}

//@ts-ignore
const hasRole = (user: number, roles: {}) => roles.role.includes(user);

function Routing(props: Props) {
  const {
    getUserRoleId,
    subject,
    dispatch,
    tokenSubject,
    schedulerAssessmentSubject,
    ilpsubject,
    getFlagrKeys,
    MyPathLevelSubject,
    MyPathBreadcrumbsSubjectObservable,
    standardsProficiencyReportBreadcrumbsObservable,
    getBreadCrumbsData,
    MyPathReassignSubject,
    assignmentBuilderBreadcrumbSubject,
    assignmentBuilderCustomPropsSubject,
    BenchmarkGrowthReportBreadcrumbSubject,
    BenchmarkGrowthReportBreadcrumbSubjectObservable,
    BenchmarkGrowthReportBackSubject,
    getAssessmentTab,
    getUpdateLevel,
    ActivityIndicatorObservable,
    mpngClassSummarySubject,
    mpngStudentIlpSubject,
    mpngStudentIlpObservable,
    dsdsReportSubject,
    studentProgressSubject,
    subjectMFE,
    timeZoneSubject,
    MyPathLoaderSubject,
    MyPathLoaderSubjectObservable,
    getSubjectName,
    mypathReportActiveFlagsSubject
  } = props;

  const wneadmin = [1];
  const wnesupport = [1, 2];
  const wnehelpdesk = [7];
  const districtadmin = [3];
  const schooladmin = [4];
  const educator = [5];
  const screens = ["", "signin", "page-not-found", "forgotpassword", "resetpassword", "impersonation-error"];
  const [breakPoint, setBreakPoint] = useState("xxl");
  GetBreakpoint(setBreakPoint);
  useEffect(() => {
    dispatch(updateBreakpoint(breakPoint));
  }, [breakPoint]);

  // If subject chnages, send to both ILP and Class Summary
  useEffect(() => {
    const ilpdata = mpngStudentIlpSubject.getValue();
    if (getSubjectName !== ilpdata.subject) {
      mpngStudentIlpSubject.next({ ...ilpdata, subject: getSubjectName });
    }

    const classData = mpngClassSummarySubject.getValue();
    if (getSubjectName !== classData.subject) {
      mpngClassSummarySubject.next({
        ...classData,
        subject: getSubjectName
      });
    }
  }, [getSubjectName]);

  function getPath(path: string) {
    return path.substring(0, path.lastIndexOf("/"));
  }

  useEffect(() => {
    subject.subscribe((data: BreadcrumbsData) => {
      let crumb = JSON.parse(localStorage.getItem("persist:ala-admin") || "");
      crumb = JSON.parse(crumb.getBreadCrumbsData);

      if (window.location.pathname.includes("reports/usage")) {
        localStorage.setItem("micro:crumbs", JSON.stringify([]));
        const subjectDataArray = [...data.crumbs].reverse();
        const subjectCrumbArray = [...crumb.crumbs].reverse();
        const removeDuplicateNames = [...subjectDataArray, ...subjectCrumbArray].filter(
          (item: CrumbsData, index: number, array: CrumbsData[]) => {
            return array.findIndex((child: CrumbsData) => child.name === item.name) === index;
          }
        );

        let removeDuplicatePaths = removeDuplicateNames.filter(
          (item: CrumbsData, index: number, array: CrumbsData[]) => {
            return array.findIndex((child: CrumbsData) => child.path === item.path) === index;
          }
        );
        removeDuplicatePaths = [...removeDuplicatePaths].reverse();

        localStorage.setItem("micro:crumbs", JSON.stringify(removeDuplicatePaths));
        dispatch(updateBreadCrumbs({ crumbs: removeDuplicatePaths }));
      }
    });
    assignmentBuilderBreadcrumbSubject.subscribe((data: BreadcrumbsData) => {
      let crumb = JSON.parse(localStorage.getItem("persist:ala-admin") || "");
      crumb = JSON.parse(crumb.getBreadCrumbsData);
      if (
        window.location.pathname.includes("/assignmentbuilder") &&
        data.crumbs !== undefined &&
        data.crumbs.length > 0
      ) {
        let removeDuplicatePaths;
        if (data.isBreadcrumbsChanged) {
          dispatch(updateBreadCrumbs({ crumbs: data.crumbs }));
        } else {
          localStorage.setItem("micro:crumbs", JSON.stringify([]));
          const abDataArray = [...data.crumbs].reverse();
          const abCrumbArray = [...crumb.crumbs].reverse();
          const removeDuplicateNames = [...abDataArray, ...abCrumbArray].filter(
            (item: CrumbsData, index: number, array: CrumbsData[]) => {
              return array.findIndex((child: CrumbsData) => child.name === item.name) === index;
            }
          );

          removeDuplicatePaths = removeDuplicateNames.filter((item: CrumbsData, index: number, array: CrumbsData[]) => {
            return array.findIndex((child: CrumbsData) => child.path === item.path) === index;
          });
          removeDuplicatePaths = removeDuplicatePaths.reverse();
          removeDuplicatePaths = [...removeDuplicatePaths];

          localStorage.setItem("micro:crumbs", JSON.stringify(removeDuplicatePaths));
          dispatch(updateBreadCrumbs({ crumbs: removeDuplicatePaths }));
        }
      }
    });
    MyPathBreadcrumbsSubjectObservable.subscribe((data: BreadcrumbsData) => {
      let crumb = JSON.parse(localStorage.getItem("persist:ala-admin") || "");
      crumb = JSON.parse(crumb.getBreadCrumbsData);
      if (
        window.location.pathname.includes("mypath-assessment-reports/") &&
        data.crumbs !== undefined &&
        data.crumbs.length > 0
      ) {
        localStorage.setItem("micro:crumbs", JSON.stringify([]));
        const myPathDataArray = [...data.crumbs].reverse();
        const myPathCrumbArray = [...crumb.crumbs].reverse();
        const removeDuplicateNames = [...myPathDataArray, ...myPathCrumbArray].filter(
          (item: CrumbsData, index: number, array: CrumbsData[]) => {
            return array.findIndex((child: CrumbsData) => child.name === item.name) === index;
          }
        );

        let removeDuplicatePaths = removeDuplicateNames.filter(
          (item: CrumbsData, index: number, array: CrumbsData[]) => {
            return array.findIndex((child: CrumbsData) => child.path === item.path) === index;
          }
        );

        removeDuplicatePaths = [...removeDuplicatePaths].reverse();

        localStorage.setItem("micro:crumbs", JSON.stringify(removeDuplicatePaths));

        dispatch(updateBreadCrumbs({ crumbs: removeDuplicatePaths }));
      }
    });
    standardsProficiencyReportBreadcrumbsObservable.subscribe((breadCrumbsData) => {
      const data = getStructuredBreadCrumbsData(breadCrumbsData);
      let crumb = JSON.parse(localStorage.getItem("persist:ala-admin") || "");
      crumb = JSON.parse(crumb.getBreadCrumbsData);
      if (
        window.location.pathname.includes("cumulativestandardsproficiency/") &&
        data.crumbs !== undefined &&
        data.crumbs.length > 0
      ) {
        localStorage.setItem("micro:crumbs", JSON.stringify([]));
        const standardReportDataArray = [...data.crumbs].reverse();
        const standardReportCrumbArray = [...crumb.crumbs].reverse();
        const removeDuplicateNames = [...standardReportDataArray, ...standardReportCrumbArray].filter(
          (item: CrumbsData, index: number, array: CrumbsData[]) => {
            return array.findIndex((child: CrumbsData) => child.name === item.name) === index;
          }
        );

        let removeDuplicatePaths = removeDuplicateNames.filter(
          (item: CrumbsData, index: number, array: CrumbsData[]) => {
            return array.findIndex((child: CrumbsData) => child.path === item.path) === index;
          }
        );

        removeDuplicatePaths = [...removeDuplicatePaths].reverse();

        localStorage.setItem("micro:crumbs", JSON.stringify(removeDuplicatePaths));

        dispatch(updateBreadCrumbs({ crumbs: removeDuplicatePaths }));
      }
    });
    BenchmarkGrowthReportBreadcrumbSubjectObservable.subscribe((data: BenchmarkGrowthReport) => {
      let crumb = JSON.parse(localStorage.getItem("persist:ala-admin") || "");
      crumb = JSON.parse(crumb.getBreadCrumbsData);
      if (
        window.location.pathname.includes("/benchmark-growth-reports") &&
        data.crumbs !== undefined &&
        data.crumbs.length > 0
      ) {
        localStorage.setItem("micro:crumbs", JSON.stringify([]));
        const benchmarkDataArray = [...data.crumbs].reverse();
        const benchmarkCrumbArray = [...crumb.crumbs].reverse();
        const removeDuplicateNames = [...benchmarkDataArray, ...benchmarkCrumbArray].filter(
          (item: CrumbsData, index: number, array: CrumbsData[]) => {
            return array.findIndex((child: CrumbsData) => child.name === item.name) === index;
          }
        );

        let removeDuplicatePaths = removeDuplicateNames.filter(
          (item: CrumbsData, index: number, array: CrumbsData[]) => {
            return array.findIndex((child: CrumbsData) => child.path === item.path) === index;
          }
        );

        removeDuplicatePaths = [...removeDuplicatePaths].reverse();

        localStorage.setItem("micro:crumbs", JSON.stringify(removeDuplicatePaths));

        dispatch(updateBreadCrumbs({ crumbs: removeDuplicatePaths }));
      }
    });
    BenchmarkGrowthReportBackSubject.subscribe((data: boolean) => {
      let tabURLs =
        window.location.origin +
        `/${localStorage.getItem("roleLevel")}/${window.location.pathname.split("/").reverse()[0]}`;
      if (data) {
        let crumb = JSON.parse(localStorage.getItem("persist:ala-admin") || "");
        let getBreadcrumbsData = JSON.parse(crumb.getBreadCrumbsData);
        let getSchoolName = getBreadcrumbsData.crumbs[getBreadcrumbsData.crumbs.length - 1].name;
        let getSchoolGuidsData = JSON.parse(crumb.getSchoolGuids);
        let getOrgGuid = getSchoolGuidsData.filter((schoolObj) => schoolObj.name === getSchoolName);
        const windowLocation = () => {
          if (getSchoolGuidsData.length > 1) {
            window.location.replace(`${window.location.origin}/reportcards/${getOrgGuid[0].orgGUID}/${getSchoolName}`);
          } else {
            window.location.replace(window.location.origin + "/reportcards");
          }
        };
        localStorage.getItem("roleLevel") === "reportcards" ? windowLocation() : window.location.replace(tabURLs);
        dispatch(updateTabName("reports"));
      }
    });
    ActivityIndicatorObservable.subscribe((data: boolean) => {
      //do nothing
    });

    // When subject in class summary or student progress changes, update state
    mpngClassSummarySubject.subscribe((data) => {
      if (data?.subject.length > 0 && data?.subject !== getSubjectName) {
        dispatch(updateSubjectName(data?.subject));
      }
    });
    mpngStudentIlpSubject.subscribe((data) => {
      if (data?.subject.length > 0 && data.subject !== getSubjectName) {
        dispatch(updateSubjectName(data?.subject));
      }
    });
  }, []);

  useEffect(() => {
    const checktoken = setInterval(() => {
      if (GetToken() !== "No Token") {
        clearInterval(checktoken);
        setInterval(async () => {
          //@ts-ignore
          let nbf = jwt_decode(GetToken()).nbf;
          //@ts-ignore
          let timeDiff = new Date().getTime() - new Date(nbf * 1000).getTime();
          timeDiff = Math.ceil(timeDiff / 1000 / 60);
          if (timeDiff >= parseInt(window.APP_ENV.token_expiry_time)) {
            getUser()
              .then(async (user: User) => {
                if (user) {
                  if (!localStorage.getItem("lms:isRenew") || localStorage.getItem("lms:isRenew") === "false") {
                    localStorage.setItem("lms:isRenew", "true");
                    try {
                      await renewToken().then(async (user) => {
                        tokenSubject.next(user.access_token);
                        schedulerAssessmentSubject.next({
                          token: user.access_token,
                          guid: ""
                        });
                        //TODO: why is schedulerAssessmentSubject.next() called twice? Looks like a bug. Add a better comment if it should be in here.
                        schedulerAssessmentSubject.next({
                          token: user.access_token,
                          guid: ""
                        });

                        ilpsubject.next({
                          token: user.access_token,
                          classId: ""
                        });
                      });
                      localStorage.setItem("lms:isRenew", "false");
                    } catch {
                      localStorage.removeItem("oidc:session");
                      localStorage.removeItem("page");
                      localStorage.removeItem("oidc:state");
                      localStorage.removeItem("persist:mypath-reports");
                      for (let i = 0; i < localStorage.length; i++) {
                        if (localStorage.key(i).includes("oidc.")) {
                          localStorage.removeItem(localStorage.key(i));
                        }
                      }
                      logout();
                    }
                  }
                } else {
                  //do nothing
                }
              })
              .catch((error) => {
                if (error.message === "No matching state found in storage") {
                  localStorage.removeItem("oidc:state");
                  localStorage.removeItem("oidc:session");
                  localStorage.removeItem("page");
                  login();
                }
              });
          }
        }, 1000);
      } else {
        localStorage.removeItem("oidc:session");
        localStorage.removeItem("page");
      }
    }, 1000);
  }, []);

  useEffect(() => {
    //@ts-ignore
    document.getElementById("removeLoading").style.display = "none";

    if (new URL(`${window.location}`).searchParams.get("code") && window.location.pathname === "/") {
      //@ts-ignore
      localStorage.setItem(
        "ala:loginCode",
        //@ts-ignore
        new URL(`${window.location}`).searchParams.get("code")
      );
    }
    if (!localStorage.getItem("ala:hasCode") && new URL(`${window.location}`).searchParams.get("code")) {
      localStorage.setItem("ala:hasCode", "true");
    }

    if (!RoutesHelper.includes(window.location.pathname.split("/")[1])) {
      window.location.assign(`http://${window.location.host}/page-not-found`);
    } else {
      if (
        (!HasSession() && !screens.includes(window.location.pathname.split("/")[1])) ||
        (!getFlagrKeys.MyPathAssessmentReports && window.location.pathname.includes("mypath-assessment")) ||
        (!getFlagrKeys.DomainMappingFeature && window.location.pathname.includes("domainmapping"))
      ) {
        window.location.assign("/");
      }
    }
  }, [screens]);

  const updateAssessmentTabFunc = () => {
    if (!window.location.pathname.includes("assessmentschedule")) {
      if (getAssessmentTab === "schedule" && localStorage.getItem("page") !== "Assessments") {
        dispatch(updateAssessmentTab("home"));
      } else if (getAssessmentTab === "settings") {
        if (
          (getUpdateLevel === "district" && localStorage.getItem("page") === "Assessments") ||
          (getUpdateLevel === "school" && localStorage.getItem("page") === "Assessments")
        ) {
          dispatch(updateAssessmentTab(getAssessmentTab));
        } else {
          dispatch(updateAssessmentTab("home"));
        }
      }
    }
  };

  useEffect(() => {
    updateAssessmentTabFunc();
    if (!window.location.pathname.includes("/reports/usage")) {
      dispatch(updatePreviousUrl(window.location.pathname));
    }
    if (
      window.location.pathname.includes("mypath-assessment-reports") &&
      (window.location.pathname.includes("school") || window.location.pathname.includes("district"))
    ) {
      MyPathLevelSubject.next({
        name: getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
      });
    }

    getUserRoleId === 7 ? MyPathReassignSubject.next(false) : MyPathReassignSubject.next(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <>
      <LogProvider devURL={["localhost:3000", "wneapp.dev.edgenuityapp.com", "app.dev.edgenuityapp.com"]} x={1} y={1} />

      <PageTitle />
      <Router>
        <Routes>
          <Route path="/" element={<LoginComponent />} />
          <Route path="/signin" element={<Signin tokenSubject={tokenSubject} subjectMFE={subjectMFE} />} />
          {hasRole(getUserRoleId, { role: wnehelpdesk }) && (
            <Route
              path="customers"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Customers />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wnehelpdesk }) && (
            <Route
              path="users"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Users />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wnehelpdesk }) && (
            <Route
              path="schools"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Schools />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wnehelpdesk }) && (
            <Route
              path="institutions/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Institutions
                    schedulerAssessmentSubject={schedulerAssessmentSubject}
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wnehelpdesk }) && (
            <Route
              path="school/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <School
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wnesupport }) && (
            <Route
              path="customers"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Customers />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wnesupport }) && (
            <Route
              path="users"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Users />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wnesupport }) && (
            <Route
              path="schools"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Schools />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wnesupport }) && (
            <Route
              path="institutions/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Institutions
                    schedulerAssessmentSubject={schedulerAssessmentSubject}
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wnesupport }) && (
            <Route
              path="school/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <School
                    schedulerAssessmentSubject={schedulerAssessmentSubject}
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: wneadmin }) && getFlagrKeys.DomainMappingFeature && (
            <Route
              path="domainmapping"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <DomainMapping />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 2, 3, 4, 5, 7] }) && (
            <Route
              path="classview/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <ClassView
                    ilpsubject={ilpsubject}
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                    mpngClassSummarySubject={mpngClassSummarySubject}
                    mpngStudentIlpSubject={mpngStudentIlpSubject}
                    mpngStudentIlpObservable={mpngStudentIlpObservable}
                    dsdsReportSubject={dsdsReportSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: districtadmin }) && (
            <Route
              path="dainstitutions/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <DAInstitutions
                    schedulerAssessmentSubject={schedulerAssessmentSubject}
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                    dsdsReportSubject={dsdsReportSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: districtadmin }) && (
            <Route
              path="daschool/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <DASchool
                    schedulerAssessmentSubject={schedulerAssessmentSubject}
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                    dsdsReportSubject={dsdsReportSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: educator }) && (
            <Route
              path="dalist/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <DAList />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: schooladmin }) && (
            <Route
              path="saclasslist/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SAClassList
                    schedulerAssessmentSubject={schedulerAssessmentSubject}
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: schooladmin }) && (
            <Route
              path="saschools/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SASchools />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: schooladmin }) && (
            <Route
              path="saschools/reports/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SASchools
                    isFrom="reports"
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: schooladmin }) && (
            <Route
              path="saclasses/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SAClasses />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: schooladmin }) && (
            <Route
              path="sausers/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SAUsers />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: schooladmin }) && (
            <Route
              path="sasettings/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SASettings />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: schooladmin }) && (
            <Route
              path="saclassview/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SAClassView />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: schooladmin }) && (
            <Route
              path="/sessions/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <AcademicSessions isFrom="SingleSchoolAdmin" />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: educator }) && (
            <Route
              path="teacherschools/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <TeacherSchools />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: educator }) && (
            <Route
              path="schools/reports/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <TeacherSchools isFrom="reports" />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: educator }) && (
            <Route
              path="teacherassessmentschools/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <TeacherSchools isFrom="Assessment" />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: educator }) && (
            <Route
              path="teacherclasses/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <TeacherClasses isfrom="school" />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: educator }) && (
            <Route
              path="teacherclass/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <TeacherClasses />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: educator }) && (
            <Route
              path="tstudents/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <TStudents />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: educator }) && (
            <Route
              path="teacherclassview/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <TeacherClassView />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [3, 4, 5] }) && (
            <Route
              path="eusers"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <EUsers />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [3, 4, 5] }) && (
            <Route
              path="eadmin"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <EAdmin />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 2, 7] }) && (
            <Route
              path="cvieweducator/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <CustomerEducatorProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [3] }) && (
            <Route
              path="dvieweducator/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <DistrictEducatorProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [4] }) && (
            <Route
              path="svieweducator/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SchoolEducatorProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [4] }) && (
            <Route
              path="suvieweducator/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <USchoolEducatorProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [4, 3] }) && (
            <Route
              path="duvieweducator/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <DSchoolEducatorProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 2, 7] }) && (
            <Route
              path="searchvieweducator/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <UserEducatorProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 7] }) && (
            <Route
              path="wneuser/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <WNEProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [3, 4] }) && (
            <Route
              path="searchdvieweducator/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SAUserEducatorProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 2, 3, 4, 7] }) && (
            <Route
              path="vieweducator/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <EducatorProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [3, 4] }) && (
            <Route
              path="studentplacement"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <StudentView />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [4] }) && (
            <Route
              path="/sessions"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <AcademicSessions isFrom="" />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 2, 3, 4, 7] }) && (
            <Route
              path="cstudentplacement/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SchoolStudentPlacement />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 2, 3, 4, 5, 7] }) && (
            <Route
              path="viewstudent/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <StudentsProfile />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [3, 4] }) && (
            <Route
              path="bulkimports/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <BulkImportsView />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 2, 3, 4, 7] }) && (
            <Route
              path="sbulkimports/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SchoolBulkImportsView />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [3, 4, 5] }) && (
            <Route
              path="assessments/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Assessment schedulerAssessmentSubject={schedulerAssessmentSubject} />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [3, 4, 5] }) && (
            <Route
              path="saassessmentschools/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <SASchools isFrom="Assessment" />
                </BoundRoute>
              }
            />
          )}

          {hasRole(getUserRoleId, { role: [1, 2, 3, 4, 5, 7] }) && (
            <Route
              path="/cumulativestandardsproficiency/:level/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <CumulativeStandardsProficiency mpngStudentIlpSubject={mpngStudentIlpSubject} />
                </BoundRoute>
              }
            />
          )}

          <Route
            path="/profile/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <EditOwnProfile isFrom="profile" />
              </BoundRoute>
            }
          />

          <Route path="/page-not-found" element={<PageNotFound />} />
          {HasSession() ? <Route path="*" element={<PageNotFound />} /> : null}
          <Route path="/impersonation-error" element={<ImpersonationErrorPage />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="resetpassword/:token" element={<ResetPassword />} />
          <Route path="resetpassword/:id/:token" element={<ResetPassword />} />
          {hasRole(getUserRoleId, { role: [3, 4, 5] }) && (
            <Route
              path="assessments/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <Assessment />
                </BoundRoute>
              }
            />
          )}

          {hasRole(getUserRoleId, { role: [6] }) && (
            <>
              <Route path="/home" element={<StudentBoundRoute tokenSubject={tokenSubject} />} />
              <Route path="/mypathassessmentk5" element={<StudentBoundRoute tokenSubject={tokenSubject} />} />
              <Route path="/mypathassessment612" element={<StudentBoundRoute tokenSubject={tokenSubject} />} />
              <Route path="/logout" element={<Logout />} />
            </>
          )}

          {hasRole(getUserRoleId, { role: [1, 2, 3, 4, 5, 7] }) && (
            <Route
              path="/reportcards/:id/:name"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <ReportsTab
                    isMultiple={true}
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                    dsdsReportSubject={dsdsReportSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 2, 3, 4, 5, 7] }) && (
            <Route
              path="/reportcards"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <ReportsTab
                    isMultiple={false}
                    MyPathLevelSubject={MyPathLevelSubject}
                    BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                    dsdsReportSubject={dsdsReportSubject}
                  />
                </BoundRoute>
              }
            />
          )}
          {hasRole(getUserRoleId, { role: [1, 2, 3, 4, 5, 7] }) && (
            <Route
              path="/dsdsreport/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <DsdsReport dsdsReportSubject={dsdsReportSubject} />
                </BoundRoute>
              }
            />
          )}

          {hasRole(getUserRoleId, { role: [3, 4] }) && (
            <Route
              path="/dsds-report-launch/:id"
              element={
                <BoundRoute tokenSubject={tokenSubject}>
                  <DsdsReportLaunch />
                </BoundRoute>
              }
            />
          )}

          <Route
            path="/profile/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <EditOwnProfile isFrom="profile" />
              </BoundRoute>
            }
          />
          <Route
            path="/reports/usage/school/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <Reports timeZoneSubject={timeZoneSubject} />
              </BoundRoute>
            }
          />
          <Route
            path="/reports/usage/district/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <Reports timeZoneSubject={timeZoneSubject} />
              </BoundRoute>
            }
          />

          <Route
            path="/reports/usage/student/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <Reports timeZoneSubject={timeZoneSubject} />
              </BoundRoute>
            }
          />

          <Route
            path="/reports/usage/class/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <Reports timeZoneSubject={timeZoneSubject} />
              </BoundRoute>
            }
          />

          <Route
            path="/assessmentschedule"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <AssessmentSchedule />
              </BoundRoute>
            }
          />

          <Route
            path="/classprogress"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <ClassProgress />
              </BoundRoute>
            }
          />

          <Route
            path="/createstudent/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <CreateStudent />
              </BoundRoute>
            }
          />

          <Route
            path="/addstudentroster/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <AddStudentRoster />
              </BoundRoute>
            }
          />

          <Route
            path="/mypath-assessment-reports/:level/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <MyPathAssessmentReports
                  MyPathLevelSubject={MyPathLevelSubject}
                  BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                  MyPathLoaderSubject={MyPathLoaderSubject}
                  MyPathLoaderSubjectObservable={MyPathLoaderSubjectObservable}
                  mypathReportActiveFlagsSubject={mypathReportActiveFlagsSubject}
                />
              </BoundRoute>
            }
          />

          <Route
            path="/benchmark-growth-reports/:level/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <BenchmarkGrowthReports
                  BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
                />
              </BoundRoute>
            }
          />

          <Route
            path="/assignmentbuilder/:subject"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <AssignmentBuilder
                  assignmentBuilderBreadcrumbSubject={assignmentBuilderBreadcrumbSubject}
                  assignmentBuilderCustomPropsSubject={assignmentBuilderCustomPropsSubject}
                />
              </BoundRoute>
            }
          />

          <Route
            path="/assignmentbuilder/:subject/:lessonlibrary"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <AssignmentBuilder
                  assignmentBuilderBreadcrumbSubject={assignmentBuilderBreadcrumbSubject}
                  assignmentBuilderCustomPropsSubject={assignmentBuilderCustomPropsSubject}
                />
              </BoundRoute>
            }
          />

          <Route
            path="/assignmentbuilder/summaryreport/:schoolId/:assignmentId"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <AssignmentBuilder
                  assignmentBuilderBreadcrumbSubject={assignmentBuilderBreadcrumbSubject}
                  assignmentBuilderCustomPropsSubject={assignmentBuilderCustomPropsSubject}
                />
              </BoundRoute>
            }
          />

          <Route
            path="/assignmentbuilder/schools/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <AssignmentBuilderSchools />
              </BoundRoute>
            }
          />

          <Route
            path="/assignmentbuilder/districts/:id"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <AssignmentBuilderDistricts />
              </BoundRoute>
            }
          />

          <Route
            path="/noLicenseProducts"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <NoLicenseProducts />
              </BoundRoute>
            }
          />

          <Route
            path="/classsummary/:classid"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <ClassSummaryReport mpngClassSummarySubject={mpngClassSummarySubject} />
              </BoundRoute>
            }
          />

          <Route
            path="/studentprogress/classcontext/:studentid"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <ClassSummaryReport mpngClassSummarySubject={mpngClassSummarySubject} />
              </BoundRoute>
            }
          />

          <Route
            path="/studentprogress/:studentid"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <StudentProgress
                  studentProgressSubject={studentProgressSubject}
                  subjectMFE={subjectMFE}
                  // mpngClassSummarySubject={mpngClassSummarySubject}
                />
              </BoundRoute>
            }
          />

          <Route
            path="/studentclasses/:studentid"
            element={
              <BoundRoute tokenSubject={tokenSubject}>
                <StudentClasses />
              </BoundRoute>
            }
          />

          <Route path="/page-not-found" element={<PageNotFound />} />
          {HasSession() ? <Route path="*" element={<PageNotFound />} /> : null}
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="resetpassword/:token" element={<ResetPassword />} />
          <Route path="resetpassword/:id/:token" element={<ResetPassword />} />
          <Route path="launch" element={<MppLaunch />} />
        </Routes>
      </Router>
    </>
  );
}

export default connect(mapStateToProps)(Routing);
